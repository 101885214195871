<script lang="ts">
    // TODO: @Uzayn when blade suggestion box has been removed,
    //       update your server-side code to return JSON data instead of HTML for suggestions refresh

    import { onMount, afterUpdate } from 'svelte';
    import TagIcon from '@/Icons/TagIcon.svelte';
    import ArrowPathIcon from '@/Icons/ArrowPathIcon.svelte';
    import SuggestionCard from '@/Components/SuggestionCard.svelte';
    import Swiper, { Pagination, Autoplay } from 'swiper';
    import 'swiper/swiper-bundle.min.css';
    import toast from 'svelte-french-toast';

    export let suggestions: any[] = [];
    export let isMobile: boolean = false;

    let swiper: Swiper;
    let swiperContainer: HTMLElement;
    let suggestionsContent: HTMLElement;
    let csrfToken: string;

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    const baseUrl = getBaseUrl();

    $: chunkedProfiles = chunkArray(suggestions, isMobile ? 2 : 3);

    function chunkArray<T>(array: T[], size: number): T[][] {
        return Array.from({ length: Math.ceil(array.length / size) }, (_, i) => array.slice(i * size, i * size + size));
    }

    function initSwiper() {
        if (swiper && !swiper.destroyed) {
            swiper.destroy();
        }

        Swiper.use([Pagination, Autoplay]);
        swiper = new Swiper(swiperContainer, {
            slidesPerView: 1,
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true,
            },
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
            },
        });
    }

    onMount(() => {
        csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
        initSwiper();
    });

    afterUpdate(() => {
        if (swiperContainer) {
            initSwiper();
        }
    });

    async function loadSuggestions(options: { free?: boolean; isMobile?: boolean } = {}) {
        try {
            const response = await fetch(`${baseUrl}/suggestions/members`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ filters: options }),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.success) {
                appendSuggestionsResults(result.data);
                toast.success('Suggestions list refreshed');
            } else {
                toast.error('Error fetching suggestions');
            }
        } catch (error) {
            // console.error('Error fetching suggestions:', error);
            toast.error('Error fetching suggestions');
        }
    }

    function appendSuggestionsResults(data: { html: string }) {
        if (suggestionsContent) {
            suggestionsContent.innerHTML = data.html;
        }
    }

    function launchToast(type: string, title: string, message: string) {
        // Implement toast notification
        console.log(`${type} - ${title}: ${message}`);
    }

    function trans(key: string): string {
        // Implement translation function
        return key;
    }
</script>

<div
    class="suggestions-box {isMobile
        ? 'suggestions-box-mobile'
        : ''} rounded-lg border px-2 dark:!border-neutral-800 {isMobile ? 'pb-1 pt-3' : 'pb-0 pt-4'}"
>
    <div class="flex items-center justify-between px-1">
        <h5 class="pl-2 text-xl font-semibold">Suggestions</h5>
        <div class="flex space-x-4">
            <button
                class="cursor-pointer"
                title="Free account only"
                on:click="{() => loadSuggestions({ free: true, isMobile })}"
                aria-label="Free account only"
            >
                <TagIcon className="h-5 w-5" />
            </button>
            <button
                class="cursor-pointer"
                title="Refresh suggestions"
                on:click="{() => loadSuggestions({ isMobile })}"
                aria-label="Refresh suggestions"
            >
                <ArrowPathIcon className="h-5 w-5" />
            </button>
        </div>
    </div>

    <div class="suggestions-content" bind:this="{suggestionsContent}">
        {#if suggestions.length > 0}
            <div class="swiper-container mySwiper" bind:this="{swiperContainer}">
                <div class="swiper-wrapper">
                    {#each chunkedProfiles as profilesSet}
                        <div class="swiper-slide px-1">
                            {#each profilesSet as profile}
                                <SuggestionCard {profile} isListMode="{false}" isListManageable="{false}" />
                            {/each}
                        </div>
                    {/each}
                </div>
                <div class="swiper-pagination"></div>
            </div>
        {:else}
            <p class="text-center">No suggestions available</p>
        {/if}
    </div>
</div>

<style lang="postcss">
    :global(.swiper-container) {
        @apply h-full w-full;
    }

    :global(.swiper-pagination) {
        @apply relative bottom-0 left-0 mt-4 flex w-full justify-center;
    }

    :global(.swiper-pagination-bullet) {
        @apply mx-1 inline-block h-2 w-2 rounded-full bg-black opacity-20;
    }

    :global(.swiper-pagination-bullet-active) {
        @apply bg-indigo-600 opacity-100;
    }
</style>
